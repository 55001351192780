import { MenuEntry } from '@pancakeswap-libs/uikit'


const homepage = "https://app.sishi.finance/"

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: `https://sishi.finance`,
  },
  {
    label: 'sVault',
    icon: 'VaultIcon',
    href: `${homepage}`,
  },
  {
    label: 'sIndex',
    icon: 'LayerIcon',
    href: `${homepage}/index/0x029D992Ec9FB96a264aE276DcCCD4d3A1309e095#mint`,
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    href: '/swap',
  },
  // {
  //   label: 'sFarm',
  //   icon: 'FarmIcon',
  //   href: `${homepage}farms`,
  // },
  // {
  //   label: 'Staking',
  //   icon: 'PoolIcon',
  //   href: `${homepage}nests`,
  // },
  // {
  //   label: 'sClass',
  //   icon: 'VaultIcon',
  //   href: `${homepage}sclass`,
  // },
  // {
  //   label: 'sEarn',
  //   icon: 'VaultIcon',
  //   href: `${homepage}vault-workers`,
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: 'https://pancakeswap.finance/lottery',
  // },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: 'https://pancakeswap.finance/nft',
  // },
  // {
  //   label: 'Teams & Profile',
  //   icon: 'GroupsIcon',
  //   calloutClass: 'rainbow',
  //   items: [
  //     {
  //       label: 'Leaderboard',
  //       href: 'https://pancakeswap.finance/teams',
  //     },
  //     {
  //       label: 'Task Center',
  //       href: 'https://pancakeswap.finance/profile/tasks',
  //     },
  //     {
  //       label: 'Your Profile',
  //       href: 'https://pancakeswap.finance/profile',
  //     },
  //   ],
  // },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'Overview',
  //       href: 'https://pancakeswap.info',
  //     },
  //     {
  //       label: 'Tokens',
  //       href: 'https://pancakeswap.info/tokens',
  //     },
  //     {
  //       label: 'Pairs',
  //       href: 'https://pancakeswap.info/pairs',
  //     },
  //     {
  //       label: 'Accounts',
  //       href: 'https://pancakeswap.info/accounts',
  //     },
  //   ],
  // },
  // {
  //   label: 'IFO',
  //   icon: 'IfoIcon',
  //   href: 'https://pancakeswap.finance/ifo',
  // },


  {
    label: 'sVote',
    icon: 'GroupsIcon',
    href:'https://snapshot.org/#/sishi.eth',
    newTab: true,
  },
  {
    label: 'sDocs',
    icon: 'GitbookIcon',
    href:'https://docs.sishi.finance',
    newTab: true,
  },
  {
    label: 'Audit',
    icon: 'AuditIcon',
    href:'https://docs.sishi.finance/security/audit',
    newTab: true,
  },
  // {
  //   label: 'Listing',
  //   icon: 'LayerIcon',
  //   items: [
  //     {
  //       label: 'CoinMarketCap',
  //       href: 'https://coinmarketcap.com/currencies/sishi-finance/',
  //       newTab: true,
  //     },
  //     {
  //       label: 'DappRadar',
  //       href: 'https://dappradar.com/binance-smart-chain/defi/sishi-finance',
  //       newTab: true,
  //     },
  //     {
  //       label: 'CoinGecko',
  //       href: 'https://www.coingecko.com/en/coins/sishi-finance',
  //       newTab: true,
  //     },
  //     {
  //       label: 'Coinpaprika',
  //       href: 'https://coinpaprika.com/coin/sishi-sishi-token/',
  //       newTab: true,
  //     },
  //   ],
  // },
  // {
  //   label: 'Communities',
  //   icon: 'LayerIcon',
  //   items: [
  //     {
  //       label: 'Telegram',
  //       href: 'https://t.me/sishi_finance',
  //       newTab: true,
  //     },
  //     {
  //       label: 'Twitter',
  //       href: 'https://twitter.com/0xsishi',
  //       newTab: true,
  //     },
  //     {
  //       label: 'Discord',
  //       href: 'https://discord.com/invite/aGQsMxmZbK',
  //       newTab: true,
  //     },
  //   ],
  // },
  // {
  //   label: 'More',
  //   icon: 'MoreIcon',
  //   items: [
  //     {
  //       label: 'Github',
  //       href: 'https://github.com/sishi-finance/',
  //       newTab: true,
  //     },
  //     {
  //       label: 'Medium',
  //       href: 'https://0xsishi.medium.com/',
  //       newTab: true,
  //     },
  //     {
  //       label: 'Publish0x',
  //       href: 'https://www.publish0x.com/sishi-finance',
  //       newTab: true,
  //     },
  //   ],
  // },
]

export default config
